import React from "react"
import BtfData from "./data/btf_cb_ez.json"
import Styles from "./css/btf_cb_ez.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"

class Btf_cb_ez extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: BtfData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if (typeof window != "undefined") {
      window.mapParams();
    }
    });
  }

  render() {
    return (

      <div className = {Styles.btfCbez} style={{ display: this.state.display }}>

              <section className={Styles.bf}>
              <h3>{this.state.data.bfHeadline}</h3>
              <h4>{this.state.data.bfSubHeadline}</h4>
              <img className = {Styles.productSS} src={this.state.data.productImg} />
              <button className = {`${Styles.install} cl1`} data-cy="cta"
                dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }} onClick = {() => window.triggerInstall()}></button>

                {this.props.children}

                <div className={Styles.freedom}>
                <h5>{this.state.data.freedomSubHeadline}</h5>
                <div className={Styles.quadtych}>
                <div>
                <img className = {Styles.productSS} src={this.state.data.freedom1img} />
                <h6>{this.state.data.freedom1}</h6></div>
                <div>
                <img className = {Styles.productSS} src={this.state.data.freedom2img} />
                <h6>{this.state.data.freedom2}</h6></div>
                <div>
                <img className = {Styles.productSS} src={this.state.data.freedom3img} />
                <h6>{this.state.data.freedom3}</h6></div>
                <div>
                <img className = {Styles.productSS} src={this.state.data.freedom4img} />
                <h6>{this.state.data.freedom4}</h6></div>
                </div>
              </div>
              </section>

        		<footer>
        		<ul className={Styles.footerBottom}>
              <li>Tightrope Interactive</li>
              <li><a href="https://clearbar.app/terms-of-service/" target="_blank">Terms</a></li>
              <li><a href="https://clearbar.app/privacy-policy/" target="_blank">Privacy</a></li>
              <li><a href="https://clearbar.app/contact-us/" target="_blank">Contact</a></li>
              <li><a href="https://clearbar.app/uninstall/" target="_blank">Uninstall</a></li>
              <li><a href="https://clearbar.app/privacy-policy/#cali-consumer" target="_blank">Do Not Sell My Personal Information</a></li>
              <li>All trademarks are property of their respective owners</li>
            </ul>
        		</footer>
            </div>








    )
  }
}

export default Btf_cb_ez
